// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfigProd = {
  apiKey: process.env.REACT_APP_API_KEY_PROD,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN_PROD,
  projectId: process.env.REACT_APP_PROJECT_ID_PROD,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET_PROD,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID_PROD,
  appId: process.env.REACT_APP_ID_PROD,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID_PROD,
};

export default firebaseConfigProd;
