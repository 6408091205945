import logo from "../../assets/images/logo.png";
import store from "../../assets/images/store.svg";
import { FaGoogle } from "react-icons/fa";
import { BsApple } from "react-icons/bs";
import { BiEnvelope } from "react-icons/bi";
import { Link, useLocation } from "react-router-dom";
import { signInWithPopup } from "firebase/auth";
import { auth, provider } from "../../firebaseConfig";
import { useEffect, useState } from "react";
import "./home.css";

console.log(process.env.NODE_ENV, 'node environment')

const Home = () => {
  const [deviceType, setDeviceType] = useState("");
  const [deviceVersion, setDeviceVersion] = useState("");
  const [fcmToken, setfcmToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    setDeviceType(queryParams.get("device_type"));
    setDeviceVersion(queryParams.get("device_version"));
    setfcmToken(queryParams.get("fcm_token"));
  }, [deviceType, deviceVersion, fcmToken, location.search]);

  const handleSubmit = () => {
    setLoading(true);
    setError("");
    signInWithPopup(auth, provider).then((data) => {
      let provider = data.user.providerData[0].providerId.split(".")[0];
      let providerId = data.user.providerData[0].uid;
      getUserDetails(provider, providerId);
    });
  };

  // Get user details
  const getUserDetails = (provider, providerId) => {
    const url = process.env.REACT_APP_BASEURL;
    fetch(`${url}/api/v1/users/social-login-data/${provider}/${providerId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === "error") {
          setError(response.message);
          setLoading(false);
        } else {
          let firstName = response.data.first_name;
          let lastName = response.data.last_name;
          let email = response.data.email;
          let fcmToken = response.data.fcm_token;
          let deviceType = response.data.device_type;
          let deviceVersion = response.data.device_version;
          let provider = response.data.social_login.provider;
          let providerId = response.data.social_login.provider_id;
          handleLogin(
            firstName,
            lastName,
            email,
            fcmToken,
            deviceType,
            deviceVersion,
            provider,
            providerId
          );
        }
      })
      .catch((error) => {
        // Handle any errors here
        console.log(error);
        setLoading(false);
        setError("Account does not exist, you need to create an account");
      });
  };

  // Handle Login
  const handleLogin = (
    firstName,
    lastName,
    email,
    fcmToken,
    deviceType,
    deviceVersion,
    provider,
    providerId
  ) => {
    const url = process.env.REACT_APP_BASEURL;
    fetch(`${url}/api/v1/users/social-login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        first_name: firstName,
        last_name: lastName,
        email,
        provider,
        provider_id: providerId,
        device_type: deviceType,
        device_version: deviceVersion,
        fcm_token: fcmToken,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        localStorage.setItem("authUser", response.data.token);
        localStorage.setItem("uuid", response.data.user.uuid);
        if (response.data.user.identity_verification_status === "verified") {
          window.location = `/dashboard`;
        } else if (
          response.data.user.identity_verification_status === "pending"
        ) {
          window.location = `/verify`;
        } else {
          window.location = `/submit-identification`;
        }
      })
      .catch((error) => {
        // Handle any errors here
        console.log(error);
        setLoading(false);
        setError("Account does not exist, you need to create an account");
      });
  };

  return (
    <div className="container">
      <nav className="d-none d-md-block d-lg-block">
        <img src={logo} alt="logo" width={30} className="img-fluid mt-4" />
      </nav>
      <div className="row">
        <div className="col-md-6 d-none d-md-block d-lg-block">
          <img src={store} alt="dilivva" className="img-fluid mt-4" />
          <p className="fw-bold mt-4">
            If you don't have the Dilivva App, you can download it on
          </p>
          <div className="apps">
            <a href="https://play.google.com/store/apps/details?id=com.nqb8.dilivvasender">
              <img
                src="https://dilivva.com/static/media/google-play-app-store.d21ab4597641d26a1a62.png"
                className="img-fluid m-2 app-img"
                width="150"
                alt="google-play"
              />
            </a>
            <a href="https://apps.apple.com/us/app/dilivva-sender/id1662208767">
              <img
                src="https://dilivva.com/static/media/app-store.4bc811d887010296ae6b.png"
                className="img-fluid m-2 app-img"
                width="150"
                alt="app-store"
              />
            </a>
          </div>
        </div>

        <div className="col-md-6 mt-5">
          <div className="d-md-none d-lg-none text-center my-5">
            <img src={logo} alt="logo" className="mt-4 mb-5" />
          </div>
          <div>
            {error !== "" && (
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            )}
            <h1 className="mt-5">Welcome Back!</h1>
            <small className="mb-5">Sign in to your account with ease</small>
            <div className="d-flex justify-content-between mt-5">
              <div className="col mx-2"></div>
            </div>
            <div className="mb-3">
              <button onClick={handleSubmit} className="social-btn">
                <span className="mx-2 h5">
                  <BsApple />
                </span>
                Continue with Apple
              </button>
            </div>
            <div className="mb-3">
              <button onClick={handleSubmit} className="social-btn">
                {loading ? (
                  <div
                    className="spinner-border text-dark"
                    role="status"
                    style={{
                      fontSize: "10px",
                      width: "25px",
                      height: "25px",
                    }}
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <>
                    <span className="mx-2 h5">
                      <FaGoogle />
                    </span>
                    Continue with Google
                  </>
                )}
              </button>
            </div>
            <div className="row mb-3 offset-1">
              <div className="col-5">
                <hr />
              </div>
              <div className="col-1">or</div>
              <div className="col-5">
                <hr />
              </div>
            </div>
            <div className="">
              <Link to={`/login`}>
                <button className="social-btn">
                  <span className="mx-2 h5">
                    <BiEnvelope />
                  </span>
                  Continue with Email
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
