import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import firebaseConfigProd from "./firebaseConfig.prod";
import firebaseConfigTest from "./firebaseConfig.test";


// TODO: Replace the following with your app's Firebase project configuration

const getFirebaseConfig = () => {
  if (process.env.NODE_ENV === 'production') {
    return firebaseConfigTest;
  } else {
    return firebaseConfigProd;
  }
};

const firebaseConfig = getFirebaseConfig()


const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();